<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col-md">
            <div class="d-inline d-inline">
              <button
                class="btn btn-falcon-primary btn-sm"
                type="button"
                @click="getToday"
              >
                Today
              </button>
            </div>
          </div>
          <div class="col-auto">
            <select
              class="form-select d-inline me-2"
              aria-label="Select order type"
              @change="orderTypeSelected"
              v-model="formData.order_type"
              style="width: 30%"
            >
              <option value="0" selected="">All locations</option>
              <option
                v-for="(item, i) in order_types"
                :key="i"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <flat-pickr
              v-model="formData.from"
              @change="dateChanged"
              class="form-control d-inline"
              placeholder="Select Start"
              style="width: 30%"
              :config="config"
            />
            To
            <flat-pickr
              v-model="formData.to"
              @change="dateChanged"
              class="form-control d-inline ml-2"
              placeholder="Select Start"
              style="width: 30%"
              :config="config"
            />
          </div>
          <div class="col-auto">
            <button
              class="btn btn-falcon-success btn-sm me-1 mb-2 mb-sm-0"
              type="button"
              @click="generateReport"
            >
              <span class="fas fa-arrow-down me-1"> </span>Download (.pdf)
            </button>
            <button
              class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0"
              type="button"
              @click="printReport"
            >
              <span class="fas fa-print me-1"> </span>Print
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row align-items-center text-center mb-3">
          <div class="col text-sm-center mt-2 mt-sm-0">
            <h4 class="my-2">Sales Report</h4>
            <h6 class="mb-0">
              {{ user.company.name }}, {{ user.company.address_line1 }}
            </h6>
            <p class="fs--1 mb-0">{{ user.company.address_line2 }}</p>
            <p class="fs--1 mb-0">{{ user.company.address_line3 }}</p>
            <p class="fs--1 mb-0">
              Date : {{ formData.from }} To : {{ formData.to }}
            </p>
          </div>
        </div>
        <div class="table-responsive scrollbar mt-1 fs--1">
          <table class="table table-striped border-bottom">
            <thead class="light">
              <tr class="bg-primary text-white dark__bg-1000">
                <th class="border-0">NAME</th>
                <th class="border-0">PRICE</th>
                <th class="border-0 text-end">QUANTITY</th>
                <th class="border-0 text-end">AMOUNT (KSH)</th>
              </tr>
            </thead>
            <tbody v-if="resources.length > 0">
              <tr v-for="(item, i) in resources" :key="i">
                <td class="mb-0 text-nowrap">
                  {{ item.name }}
                </td>
                <td class="mb-0">{{ item.price }}</td>
                <td class="mb-0 text-end">{{ item.quantity }}</td>
                <td class="mb-0 text-end">
                  {{ formatToCurrency(item.total) }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="mb-0 text-nowrap" colspan="5">No records found!</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row justify-content-end">
          <div class="col-auto">
            <table class="table table-sm table-borderless fs--1">
              <tr class="border-bottom border-bottom-2 fw-bolder text-900">
                <th>Revenue Amount:</th>
                <td class="text-end">
                  {{ formatToCurrency(summary) }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer bg-light">
        <p class="fs--1 mb-0">
          <strong>Report by: </strong>Bulla POS Restaurant!
        </p>
      </div>
    </div>
    <!-- FOR PRINTING  -->
    <VueHtml2pdf
      :key="printarea_key"
      :show-layout="false"
      :float-layout="true"
      :enable-download="enable_download"
      :preview-modal="preview_modal"
      :paginate-elements-by-height="1100"
      :pagebreak="{ mode: ['avoid-all', 'css', 'legacy'] }"
      :margin="30"
      filename="Sales Analysis Report"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="5"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <div class="card mb-n5 mx-2" style="margin-top: -1150px">
          <div class="card-body">
            <div class="row align-items-center text-center mb-3">
              <div class="col text-sm-center mt-2 mt-sm-0">
                <h4 class="my-2">Payments Analysis Report</h4>
                <h6 class="mb-0">
                  {{ user.company.name }}, {{ user.company.address_line1 }}
                </h6>
                <p class="fs--1 mb-0">{{ user.company.address_line2 }}</p>
                <p class="fs--1 mb-0">{{ user.company.address_line3 }}</p>
                <p class="fs--1 mb-0">
                  Date : {{ formData.from }} To : {{ formData.to }}
                </p>
              </div>
            </div>
            <div class="table-responsive scrollbar mt-1 fs--1">
              <table class="table table-striped border-bottom">
                <thead class="light">
                  <tr class="bg-primary text-white dark__bg-1000">
                    <th class="border-0">NAME</th>
                    <th class="border-0">PRICE</th>
                    <th class="border-0 text-end">QUANTITY</th>
                    <th class="border-0 text-end">AMOUNT (KSH)</th>
                  </tr>
                </thead>
                <tbody v-if="resources.length > 0">
                  <tr v-for="(item, i) in resources" :key="i">
                    <td class="mb-0 text-nowrap">
                      {{ item.name }}
                    </td>
                    <td class="mb-0">{{ item.price }}</td>
                    <td class="mb-0 text-end">{{ item.quantity }}</td>
                    <td class="mb-0 text-end">
                      {{ formatToCurrency(item.total) }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="mb-0 text-nowrap" colspan="5">
                      No records found!
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row justify-content-end">
              <div class="col-auto">
                <table class="table table-sm table-borderless fs--1">
                  <tr class="border-bottom border-bottom-2 fw-bolder text-900">
                    <th>Revenue Amount:</th>
                    <td class="text-end">
                      {{ formatToCurrency(summary) }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer bg-light">
            <p class="fs--1 mb-0">
              <strong>Report by: </strong>Bulla POS!
            </p>
          </div>
        </div>
      </template>
    </VueHtml2pdf>
  </div>
</template>

<script>
import moment from "moment";
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";
import VueHtml2pdf from "vue3-html2pdf";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import OrderTypeService from "../services/order_types.service";

const now = new Date();

export default {
  name: "TicketAnalysisReportView",
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    VueHtml2pdf,
  },
  created() {
    this.getUser();
    this.getAllData();
    this.getAllOrderTypes();
  },
  data() {
    return {
      summary: [],
      resources: [],
      order_types: [],
      printarea_key: 1,
      preview_modal: false,
      enable_download: false,
      formData: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        order_type: 0,
      },
      config: {
        dateFormat: "M j, Y",
      },
      user: {},
      config: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        locale: "en-us",
      },
    };
  },
  methods: {
    orderTypeSelected(e) {
      console.log(e.target.value);
      this.formData.order_type = e.target.value;
      this.getAllData();
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    formatDate(input) {
      return moment(input).format("DD-MM-YYYY HH:mm:ss");
    },

    getUser() {
      let user = localStorage.getItem("user");
      if (user) {
        this.user = JSON.parse(user);
      } else {
        this.handleLogout();
      }
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    dateChanged() {
      console.log("Date changed..");
      console.log(this.formData);
      setTimeout(() => {
        this.getAllData();
      }, 500);
    },
    async getToday() {
      this.formData.from = await moment(new Date()).format("YYYY-MM-DD");
      this.formData.to = await moment(new Date()).format("YYYY-MM-DD");
      this.getAllData();
    },
    printReport() {
      this.$store.commit("SET_LOADING", true);
      this.enable_download = false;
      this.preview_modal = true;
      this.printarea_key += 1;
      this.report_name = "Sales Analysis Report";
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
        this.$store.commit("SET_LOADING", false);
      }, 1000);
    },
    generateReport() {
      this.$store.commit("SET_LOADING", true);
      this.enable_download = true;
      this.preview_modal = false;
      this.printarea_key += 1;
      this.report_name = "Sales Analysis Report";
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
        this.$store.commit("SET_LOADING", false);
      }, 1000);
    },
    onProgress(e) {
      console.log(e);
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },

    getAllData() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.salesAnalysis(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            console.log(response.data.data);
            this.resources = response.data.data.records;
            this.summary = response.data.data.summary;
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
