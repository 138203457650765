<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col-3">
            <div class="d-inline d-inline">
              <button
                class="btn btn-falcon-primary btn-sm"
                type="button"
                @click="getToday"
              >
                Today
              </button>
            </div>
          </div>

          <div class="col-6">
            <select
              class="form-select d-inline me-2"
              aria-label="Select order type"
              @change="orderTypeSelected"
              v-model="formData.order_type"
              style="width: 50%"
            >
              <option value="0" selected="">All locations</option>

              <option
                v-for="(item, i) in order_types"
                :key="i"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>

            <flat-pickr
              v-model="formData.from"
              @change="dateChanged"
              class="form-control d-inline"
              placeholder="Select Start"
              style="width: 40%"
              :config="config"
            />
          </div>

          <div class="col-3 d-flex">
            <!-- <a
    
                  class="btn btn-falcon-info btn-sm ms-auto me-1 mb-2 mb-sm-0 d-flex"
    
                  :href="pdfUrl"
    
                  target="_blank"
    
                >
    
                  <span class="fas fa-file-pdf m-1"> </span> Pdf
    
                </a> -->

            <button
              class="btn btn-falcon-primary btn-sm me-1 mb-2 mb-sm-0 d-flex"
              type="button"
              @click="handlePrintReceipt"
            >
              <span class="fas fa-print m-1"> </span>Receipt
            </button>

            <download-excel
              :data="resources"
              name="best-selling-analysis-report-bulla"
            >
              <button
                class="btn btn-falcon-primary btn-sm me-1 mb-2 mb-sm-0 d-flex"
                type="button"
              >
                <span class="fas fa-file-excel m-1"> </span>Excel
              </button>
            </download-excel>
            <button
              class="btn btn-falcon-warning btn-sm me-1 mb-2 mb-sm-0 d-flex"
              type="button"
              @click="goToStore"
            >
              <span class="fas fa-home m-1"></span> Store
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-body">
        <div class="row align-items-center text-center mb-3">
          <div class="col text-sm-center mt-2 mt-sm-0">
            <h4 class="my-2">Stock Transfer Report</h4>

            <h6 class="mb-0">
              {{ user.company.name }}, {{ user.company.address_line1 }}
            </h6>

            <p class="fs--1 mb-0">{{ user.company.address_line2 }}</p>

            <p class="fs--1 mb-0">{{ user.company.address_line3 }}</p>

            <p class="fs--1 mb-0">Date : {{ formData.from }}</p>
          </div>
        </div>

        <div class="table-responsive scrollbar mt-1 fs--1">
          <table class="table table-striped border-bottom">
            <thead class="light">
              <tr
                style="
                  background: #eee;

                  border-bottom: 1px solid #ddd;

                  font-weight: bold;

                  color: black;
                "
              >
                <th class="border-0">Product Name</th>

                <th class="border-0">Source</th>

                <th class="border-0">Destination</th>

                <th class="border-0">Owner</th>
                <th class="border-0">Notes</th>

                <th class="border-0 text-end">Quantity</th>
              </tr>
            </thead>

            <tbody v-if="resources.length > 0">
              <tr v-for="(item, i) in resources" :key="i">
                <td class="mb-0 text-nowrap">
                  {{ item.product?.name }}
                </td>

                <td class="mb-0">{{ item.source?.name }}</td>

                <td class="mb-0">{{ item.destination?.name }}</td>

                <td class="mb-0">{{ item.owner?.first_name }}</td>
                <td class="mb-0">{{ item.note }}</td>

                <td class="mb-0 text-end">
                  {{ item.quantity }} {{ item.unit_name }}
                </td>
              </tr>
            </tbody>

            <tbody v-else>
              <tr>
                <td class="mb-0 text-nowrap" colspan="5">No records found!</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card-footer bg-light">
        <p class="fs--1 mb-0">
          <strong>Report by: </strong>Bulla POS Restaurant!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { toast } from "vue3-toastify";
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";
import VueHtml2pdf from "vue3-html2pdf";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import OrderTypeService from "../services/order_types.service";
import OrderService from "../services/orders.service";

const now = new Date();

export default {
  name: "TicketAnalysisReportView",
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    VueHtml2pdf,
  },
  created() {
    this.getUser();
    this.getAllData();
    this.getAllOrderTypes();
  },
  computed: {
    pdfUrl() {
      this.formData.company_id = this.user.company_id;
      let token = btoa(JSON.stringify(this.formData));
      return process.env.VUE_APP_BASE_URL + "low-inventory-pdf/" + token;
    },
  },
  data() {
    return {
      summary: [],
      resources: [],
      order_types: [],
      printarea_key: 1,
      preview_modal: false,
      enable_download: false,
      formData: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        order_type: 0,
      },
      user: {},
      config: {
        enableTime: false,
        dateFormat: "Y-m-d",
        locale: "en-us",
      },
    };
  },
  methods: {
    handlePrintReceipt() {
      const dateStamp = moment(new Date()).format("YYYYMMDD");
      const date = moment(new Date()).format("YYYY-MM-DD");
      this.$store.commit("SET_LOADING", true);
      this.formData.os = "windows";
      this.formData.restaurant_name = this.user.company.name;
      this.formData.date = this.formatDate(date);
      this.formData.reference = "TRN/" + dateStamp;
      this.formData.created_by = this.user.first_name;
      this.formData.address_line_1 = this.user.company.address_line1;
      this.formData.address_line_2 = this.user.company.address_line2;
      this.formData.address_line_3 = this.user.company.address_line3;
      const items = this.resources.map((el) => {
        return {
          name: el.product.name,
          price: el.owner.first_name,
          quantity: el.quantity,
          subtotal: el.destination.name,
        };
      });
      this.formData.ticket_items = JSON.stringify(
        items.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );
      this.formData.footer = "Thank you and welcome again!";
      this.formData.printer_ip = "127.0.0.1";
      OrderService.printTransferReceipt(this.formData).then(
        (response) => {
          toast.success("Receipt printed!", {
            autoClose: 2000,
          });
          console.log(response);
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          toast.error(
            error.response.data.message ||
              (error.response && error.response.data) ||
              error.mesage ||
              error.toString(),
            { autoClose: 3000 }
          );
          console.log(error);
        }
      );
    },
    orderTypeSelected(e) {
      console.log(e.target.value);
      this.formData.order_type = e.target.value;
      this.getAllData();
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    formatDate(input) {
      return moment(input).format("DD-MM-YYYY HH:mm:ss");
    },

    getUser() {
      let user = localStorage.getItem("user");
      if (user) {
        this.user = JSON.parse(user);
      } else {
        this.handleLogout();
      }
    },
    goToStore() {
      this.$router.push("/store");
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    dateChanged() {
      console.log("Date changed..");
      console.log(this.formData);
      setTimeout(() => {
        this.getAllData();
      }, 500);
    },
    async getToday() {
      this.formData.from = await moment(new Date()).format("YYYY-MM-DD");
      this.formData.to = await moment(new Date()).format("YYYY-MM-DD");
      this.getAllData();
    },
    printReport() {
      this.$store.commit("SET_LOADING", true);
      this.enable_download = false;
      this.preview_modal = true;
      this.printarea_key += 1;
      this.report_name = "Sales Analysis Report";
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
        this.$store.commit("SET_LOADING", false);
      }, 1000);
    },
    generateReport() {
      this.$store.commit("SET_LOADING", true);
      this.enable_download = true;
      this.preview_modal = false;
      this.printarea_key += 1;
      this.report_name = "Sales Analysis Report";
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
        this.$store.commit("SET_LOADING", false);
      }, 1000);
    },
    onProgress(e) {
      console.log(e);
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },

    getAllData() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.dailyTransfers(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            console.log(response.data.data);
            this.resources = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
