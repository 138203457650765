import axios from "axios";
import authHeader from "./auth-header";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class OrdersService {
  index() {
    return axios.get(VUE_APP_BASE_URL + "orders", { headers: authHeader() });
  }
  all(count) {
    let data = {
      per_page: count,
    };
    return axios.post(VUE_APP_BASE_URL + "orders-all", data, {
      headers: authHeader(),
    });
  }
  closed(data) {
    return axios.post(VUE_APP_BASE_URL + "orders-closed", data, {
      headers: authHeader(),
    });
  }
  allPage(data) {
    return axios.post(
      VUE_APP_BASE_URL + "orders-all?page=" + data.page_number,
      data,
      { headers: authHeader() }
    );
  }
  closedPage(data) {
    return axios.post(
      VUE_APP_BASE_URL + "orders-closed?page=" + data.page_number,
      data,
      { headers: authHeader() }
    );
  }
  today() {
    return axios.get(VUE_APP_BASE_URL + "orders-today", {
      headers: authHeader(),
    });
  }
  thiMonth() {
    return axios.get(VUE_APP_BASE_URL + "orders-this-month", {
      headers: authHeader(),
    });
  }
  voided(data) {
    return axios.post(VUE_APP_BASE_URL + "orders-voided", data, {
      headers: authHeader(),
    });
  }
  voidedPage(data) {
    return axios.post(
      VUE_APP_BASE_URL + "orders-voided?page=" + data.page_number,
      data,
      { headers: authHeader() }
    );
  }
  open(count) {
    let data = {
      per_page: count,
    };
    return axios.post(VUE_APP_BASE_URL + "orders-open", data, {
      headers: authHeader(),
    });
  }
  openPage(data) {
    return axios.post(
      VUE_APP_BASE_URL + "orders-open?page=" + data.page_number,
      data,
      { headers: authHeader() }
    );
  }
  openCount() {
    return axios.get(VUE_APP_BASE_URL + "orders-open-count", {
      headers: authHeader(),
    });
  }
  openByCount(per_page) {
    let data = {
      per_page: per_page,
    };
    return axios.post(VUE_APP_BASE_URL + "orders-open", data, {
      headers: authHeader(),
    });
  }
  myOrders() {
    return axios.get(VUE_APP_BASE_URL + "my-orders", { headers: authHeader() });
  }
  store(formData) {
    return axios.post(VUE_APP_BASE_URL + "orders", formData, {
      headers: authHeader(),
    });
  }
  update(formData) {
    return axios.put(VUE_APP_BASE_URL + "orders/" + formData.id, formData, {
      headers: authHeader(),
    });
  }
  delete(id) {
    return axios.delete(VUE_APP_BASE_URL + "orders/" + id, {
      headers: authHeader(),
    });
  }
  printReceiptSuper(resource) {
    return axios.post(
      "http://localhost/epos/public/api/print-receipt-supermarket",
      resource,
      { headers: authHeader() }
    );
  }
  printReceipt(resource) {
    return axios.post(
      "http://localhost/epos/public/api/print-receipt",
      resource,
      { headers: authHeader() }
    );
  }
  printAdjustmentReceipt(resource) {
    return axios.post(
      "http://localhost/epos/public/api/print-adjustment-receipt",
      resource,
      { headers: authHeader() }
    );
  }
  printTransferReceipt(resource) {
    return axios.post(
      "http://localhost/epos/public/api/print-transfer-receipt",
      resource,
      { headers: authHeader() }
    );
  }
  printRestockReceipt(resource) {
    return axios.post(
      "http://localhost/epos/public/api/print-restock-receipt",
      resource,
      { headers: authHeader() }
    );
  }
  printBill(resource) {
    return axios.post("http://localhost/epos/public/api/print-bill", resource, {
      headers: authHeader(),
    });
  }
  printOrder(resource) {
    return axios.post(
      "http://localhost/epos/public/api/print-order",
      resource,
      { headers: authHeader() }
    );
  }
}

export default new OrdersService();
